<template>
    <div class="internal-sections">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Malaka Toifasi</div>
                        <div class="filter-internal-block">
                            <el-input
                                prefix-icon="el-icon-search"
                                v-model="input1"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6" class="filter_table">
                    <el-button
                        style="margin-right: 10px"
                        @click="drawer = true"
                    >
                        Malaka Toifasi qo'shish
                    </el-button>
                    <el-dropdown class="setting-cheek ml-2">
                        <el-button
                            size="small"
                            icon="el-icon-setting"
                        ></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(column, index) in columns"
                                :key="index"
                            >
                                <el-checkbox
                                    :checked="column.show"
                                    @change="column.show = !column.show"
                                    >{{ column.title }}</el-checkbox
                                >
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/settings' }">
                    Sozlamalar
                </el-breadcrumb-item>
                <el-breadcrumb-item> Malaka Toifasi </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="internal-sections-table">
            <table class="table-my-code table-bordered">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.nameUsers.show">
                            {{ columns.nameUsers.title }}
                        </th>

                        <th v-if="columns.dataContract.show">
                            {{ columns.dataContract.title }}
                        </th>

                        <th v-if="columns.data.show">
                            {{ columns.data.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.nameUsers.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.nameUsers"
                                :placeholder="columns.nameUsers.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.dataContract.show">
                            <el-date-picker
                                :placeholder="columns.dataContract.title"
                                v-model="filterForm.dataContract"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.data.show">
                            <el-date-picker
                                :placeholder="columns.data.title"
                                v-model="filterForm.data"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="user in list"
                        :key="user.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">{{ user.id }}</td>

                        <td v-if="columns.nameUsers.show">
                            {{ user.nameUsers }}
                        </td>

                        <td v-if="columns.dataContract.show">
                            {{ user.dataContract }}
                        </td>

                        <td v-if="columns.data.show">{{ user.data }}</td>

                        <td v-if="columns.settings.show" class="settings-td">
                            <el-dropdown
                                :hide-on-click="false"
                                class="table-bottons-right"
                            >
                                <span class="el-dropdown-link more_icons">
                                    <i class="el-icon-open"></i>
                                </span>
                                <el-dropdown-menu
                                    slot="dropdown"
                                    class="dropdown-menumy-style"
                                >
                                    <el-dropdown-item icon="el-icon-view">
                                        Профиль
                                    </el-dropdown-item>

                                    <el-dropdown-item icon="el-icon-edit">
                                        Изменить
                                    </el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete">
                                        Удалить
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my-pagination">
                <el-pagination
                    background
                    :page-sizes="[100, 200, 300, 400]"
                    :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="400"
                >
                </el-pagination>
            </div>
        </div>
        <div class="my-modal-big">
            <el-drawer
                custom-class="my-modal"
                :append-to-body="true"
                title="I'm outer Drawer"
                :visible.sync="drawer"
                size="80%"
                :with-header="false"
            >
                <Create></Create>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import Create from "./components/create";
import draggable from "vuedraggable";
export default {
    name: "malakaCategory",
    components: {
        Create,
    },

    data() {
        return {
            drawer: false,
            input1: "",
            filterForm: {
                id: "",
                nameUsers: "",

                dataContract: "",
                data: "",
            },
            list: [
                {
                    id: "1",
                    nameUsers: "Malaka Toifasi",
                    dataContract: "02.05.1995",
                    data: "12.12.2020",
                },
            ],
            columns: {
                id: {
                    show: true,
                    title: "№",
                    sortable: true,
                    column: "id",
                },

                nameUsers: {
                    show: true,
                    title: "Malaka Toifasi",
                    sortable: true,
                    column: "nameUsers",
                },

                dataContract: {
                    show: true,
                    title: "Yaratilgan sana",
                    sortable: true,
                    column: "dataContract",
                },
                data: {
                    show: false,
                    title: "Изменено ",
                    sortable: true,
                    column: "data",
                },

                settings: {
                    show: true,
                    title: "Profil",
                    sortable: false,
                    column: "settings",
                },
            },
        };
    },
};
</script>
