<template>
    <div>
        <div class="modal-header">
            <div class="modal-title-my">Hujjat turi</div>
            <div>
                <el-button
                    icon="el-icon-circle-plus-outline"
                    class="asosy-btn-d"
                >
                    Saqlash
                </el-button>
                <el-button type="danger"> Chiqish </el-button>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" :model="form" class="my-form">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Nomi">
                            <el-input
                                v-model="form.name1"
                                placeholder="Nomi"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        };
    },
    computed: {},
    methods: {},
};
</script>
